export const data=[
    {
        "id": 63,
        "domain": "rtis.com",
        "wonby": null,
        "wonat": null,
        "fetched": true,
        "namecheapid": null,
        "auctionId": 3474355,
        "mymaxbid": null,
        "platform": "Dropcatch",
        "currbid": "890",
        "bidders": 71,
        "scheduled":true,
        "time_left": "1d 9h 57m",
        "age": null,
        "estibot": null,
        "auctiontype": "Dropped",
        "watchlist": false,
        "bidhistory": null,
        "bidAmount": "1000",
        "result": "",
        "endTimepst": "2023-01-14T20:00:00",
        "endTimeist": "2023-01-15 01:30 IST",
        "bidplacetime": "",
        "bidPlaced": true
    },
    {
        "id": 64,
        "domain": "moreto.com",
        "wonby": null,
        "wonat": null,
        "fetched": true,
        "namecheapid": null,
        "auctionId": 3473259,
        "mymaxbid": null,
        "scheduled":true,
        "platform": "Dynadot",
        "currbid": "800",
        "bidders": 27,
        "time_left": "0d 9h 57m",
        "age": null,
        "estibot": null,
        "auctiontype": "Dropped",
        "watchlist": false,
        "bidhistory": null,
        "bidAmount": "200",
        "result": "",
        "endTimepst": "2023-01-13T20:00:00",
        "endTimeist": "2023-01-14 01:30 IST",
        "bidplacetime": "",
        "bidPlaced": false
    },
    {
        "id": 65,
        "domain": "icom.org",
        "wonby": null,
        "wonat": null,
        "fetched": true,
        "namecheapid": null,
        "auctionId": 3475667,
        "mymaxbid": null,
        "scheduled":false,
        "platform": "Namecheap",
        "currbid": "443",
        "bidders": 33,
        "time_left": "2d 9h 56m",
        "age": null,
        "estibot": null,
        "auctiontype": "Dropped",
        "watchlist": false,
        "bidhistory": null,
        "bidAmount": "1003",
        "result": "",
        "endTimepst": "2023-01-15T20:00:00",
        "endTimeist": "2023-01-16 01:30 IST",
        "bidplacetime": "",
        "bidPlaced": false
    },
    {
        "id": 66,
        "domain": "murella.com",
        "wonby": null,
        "wonat": null,
        "fetched": true,
        "namecheapid": null,
        "auctionId": 3473321,
        "mymaxbid": null,
        "platform": "Namesilo",
        "currbid": "408",
        "bidders": 20,
        "time_left": "0d 9h 56m",
        "age": null,
        "scheduled":false,
        "estibot": null,
        "auctiontype": "Dropped",
        "watchlist": false,
        "bidhistory": null,
        "bidAmount": "100",
        "result": "",
        "endTimepst": "2023-01-13T20:00:00",
        "endTimeist": "2023-01-14 01:30 IST",
        "bidplacetime": "",
        "bidPlaced": false
    },
    {
        "id": 67,
        "domain": "nameabcbcbbcbcbbcbcb.com",
        "wonby": null,
        "wonat": null,
        "fetched": true,
        "namecheapid": null,
        "auctionId": 3473321,
        "mymaxbid": null,
        "platform": "GoDaddy",
        "currbid": "408",
        "bidders": 20,
        "time_left": "0d 9h 56m",
        "age": null,
        "scheduled":true,
        "estibot": null,
        "auctiontype": "Dropped",
        "watchlist": false,
        "bidhistory": null,
        "bidAmount": "1000",
        "result": "",
        "endTimepst": "2023-01-13T20:00:00",
        "endTimeist": "2023-01-14 01:30 IST",
        "bidplacetime": "",
        "bidPlaced": false
    }
]